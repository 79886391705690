.container {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  padding-bottom: 8em;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
}

.title {
  margin: 0 auto;
  text-align: center;
  font-size: 2em;
  padding: 0 1em 1.5em 1em;
  text-decoration: underline;
}

.wrapper {
  max-width: 1200px;
  padding: 4em;
  margin: 0 auto;
  text-align: justify;
  background: #fff;
}

.wrapper>p {
  padding: 0.5em 0;
}

.wrapper>h3 {
  padding: 1em 0;
}

.footer {
  padding: 4em 1em 0 1em;
  text-align: center;
}