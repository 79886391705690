.container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 1rem;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
}

.infoContainer {
  width: 100%;
  min-width: 35rem;
  max-width: 45rem;
}

@media (max-width: 1000px) {
  .infoContainer {
    margin-inline: auto;
    margin-bottom: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infoContainer ul {
    width: fit-content;
    text-align: start;
  }
}

.infoContainer h4 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #0163e7;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.infoContainer h2 {
  font-size: 4.8rem;
  margin-bottom: 2rem;
}

.infoContainer h3 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.calendlyContainer {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

@media (max-width: 600px) {
  .calendlyContainer {
    min-height: 100rem;
    height: 100%;
  }
}

@media (max-width: 1000px) {
  .calendlyContainer {
    min-height: 110rem;
    height: 100%;
  }
}

@media (min-width: 1800px) {
  .calendlyContainer {
    padding-bottom: 50%;
  }
}

.calendlyContainer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
}

.container p {
  font-size: 1.6rem;
}

.container ul {
  margin-left: 2rem;
}

.container li {
  list-style: disc;
}

.calendlyContainer {
  width: 100%;
}

.carouselContainer {
  margin-bottom: 1rem;
}

.carouselTitle {
  text-align: center;
  margin-top: 5rem;
  font-size: 2.5rem;
}

.listOption {
  list-style: disc;
  margin-left: 1.5rem;
}

.carrouselWrapper {
  position: absolute;
  width: 40%;
  z-index: 99;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}

.image {
  width: 70%;
  top: 0;
  height: 100%;
  background-size: contain;
  position: relative;
  border-radius: 0px 16px 16px 0px;
}

.imageWrap {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .imageWrap {
    width: 100%;
  }

  .carrouselContainer {
    right: 5%;
    left: 5%;
  }
}

.carrouselContainer .ant-carousel .slick-dots li button {
  background-color: #1d264e;
}

.carrouselContainer .ant-carousel .slick-dots li.slick-active button {
  background-color: #0163e7;
}

.wrapper {
  background: white;
  padding: 0 calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400))
    calc(20px + 100 * ((100vw - 320px) / 1400));
}

.footer {
  width: 100%;
  background: #f6f9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: calc(20px + 100 * ((100vw - 320px) / 1400)) calc(10px + 40 * ((100vw - 320px) / 1400))
    calc(20px + 100 * ((100vw - 320px) / 1400));
}

.footerContent {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  .subtitle {
    font-size: 32px;
  }
}

.footerText {
  text-align: center;
  color: #474e70;
  line-height: 1.5em;
  padding-top: clamp(1em, 10vw, 1em);
  font-weight: 400;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .footerText {
    font-size: 16px;
  }
}

.download {
  font-size: 16px;
  padding: 0.8em 1.8em;
  border-radius: 48px;
  cursor: pointer;
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .download {
    margin-left: 0em;
    margin-top: 2em;
  }
}

.subtitle {
  color: #1d264e;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  font-size: 32px;
  margin-top: 1em;
}

@media screen and (max-width: 1000px) {
  .subtitle {
    font-size: 26px;
  }
}
