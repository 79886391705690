* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  list-style: none;
  quotes: none;
  font-style: normal;
  outline: none;
  text-decoration: none;
  font-family: "Lato";
}

*:focus {
  outline: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

body {
  font-family: "Lato";
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  overflow-x: hidden;
  font-size: 14px;
}

::-webkit-scrollbar {
  display: none;
}

input {
  font-size: 16px;
}

select {
  font-size: 16px;
}

textarea {
  font-size: 16px;
}

button {
  background: transparent;
  border: 0;
  outline: 0;
}

.rotate {
  transform: rotate(-90deg);
  transform-origin:bottom right;

  position:absolute;
  top: -100vw;
  right: 0;

  height:100vw;
  width:100vh;

  background-color:#000;
  color:#fff;

  overflow:auto;
}
